@import 'styles/shared/colors.css';
@import 'internal-tools/styles/colors.css';

.table {
  height: calc(90vh - 55px);
  background-color: $white;
  overflow: auto;
  border: 1px solid $grey300;
  border-radius: 8px;
}

.leftColumn {
  width: 60%;
}

.rightColumn {
  width: 20%;
}

.accountTableCell {
  font-size: 13px;
  font-weight: 500;
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}

.accountRow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.accountLeftCell {
  composes: accountTableCell;
  padding-left: 72px;
  width: calc(66% - 72px);
}

.accountRightCell {
  composes: accountTableCell;
  composes: rightColumn;
  padding-right: 36px;
  width: 33%;
}

.rowSelected {
  composes: accountRow;
  background-color: $blueGrey500;
  color: $white;
}

.accountMainHeaderColumn {
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: $black;
  padding-top: 20px;
}

.accountHeaderRow {
  cursor: default;
  border-bottom: none;
}

.accountHeaderColumn {
  height: auto;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.7px;
}

.accountHeaderLeftColumn {
  composes: accountLeftCell;
  composes: accountHeaderColumn;
  border: none;
}

.accountHeaderRightColumn {
  composes: rightColumn;
  composes: accountHeaderColumn;
  border: none;
  width: 33%;
}

.userRow {
  color: $white;
  background-color: $blueGrey700;
  cursor: pointer;
}

.userCell {
  text-align: center;
  width: 15%;
  color: $white;
  padding: 0;
}
