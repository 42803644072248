.input {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 11px 15px !important;

  color: #2a2a2a;
  border: 2px solid #e2eaec;
  border-radius: 5px !important;
  outline: none;
  box-shadow: none;

  font-family: Inter, sans-serif;
  font-size: 16px !important;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
}

.invalidInput {
  border: 2px solid #ad1100 !important;
  background: rgb(173 17 0 / 0.05);
}

.submitBtnContainer {
  margin: 40px 35px 0;
}

.backBtnContainer {
  margin-top: 30px;
}

.submitBtn {
  width: 100%;
  padding: 15px;

  cursor: pointer;
  letter-spacing: -0.25px;

  color: #051426;
  border: none;
  border-radius: 4px;
  background: #ffa41d;

  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 21px;
}

.submitBtnInvalid {
  opacity: 0.5;
}

.optionalFieldsContainer {
  display: flex;

  margin-top: 17px;
}

.phoneNumberContainer {
  width: 100%;
  margin-right: 20px;
}

.timezoneContainer {
  width: 100%;
}

.passwordContainer,
.confirmPasswordContainer {
  width: 100%;
  margin-top: 17px;
}

.showPassword {
  position: absolute;
  right: 0;

  cursor: pointer;
}

.passwordField {
  position: relative;
}

.loginTypeDelimiter {
  height: 0;
  margin: 18px 0 26px;

  border-top: 1px solid #e3ebed;
}

.loginTypeDelimiterText {
  position: relative;
  top: -11px;

  padding: 0 11px;

  letter-spacing: 1px;

  color: #757575;
  background: white;

  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
