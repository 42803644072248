@import 'styles/shared/colors.css';
@import 'internal-tools/styles/colors.css';

.headerDetail {
  width: 80%;
  margin-top: 28px;
}

.headerDetailTitle {
  font-weight: 500;
  background-color: $darkBlueGrey;
  color: $white;
  border: none;
  font-size: 24px;
  padding: 0;
  outline: none;
  margin-right: 5px;
}

.userContainer {
  margin: 5% auto;
  text-align: center;
  width: 80%;
}

.accountList {
  width: calc(55% - 100px);
  padding-left: 62.5px;
  padding-right: 35px;
  margin-top: 30px;
  height: 100vh;
}

.accountsPage {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}

.accountDetail {
  width: 45%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $darkBlueGrey;
  color: $white;
  transition: width 0.2s;
}

.border {
  content: '';
  border: 1px solid $grey250;
}

.overlay {
  position: absolute;
  top: 65px;
  bottom: 0;
  left: 0;
  right: 0;
  background: $minBlack;
  display: flex;
  justify-content: flex-end;
  transition: background 0.2s;
}

.selectAccountReminder {
  width: 45vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(90vh - 25px);
  color: $grey600;
  font-weight: 500;
  letter-spacing: 0.7px;
}

.selectAccountIcon {
  border: 1px solid $grey250;
  border-radius: 50%;
  padding: 10px 10px 5px;
}

.ticketInput {
  background-color: $blueGrey700;
  border: 1px solid $grey500;
  font-size: 12px;
  padding: 8px;
  margin: 0 2% 0 0;
  width: 100px;
  outline: none;
  border-radius: 3px;
  color: $white;
}

.ticketInput::placeholder {
  color: $grey500;
}

.ticketInputLabel {
  font-size: 12px;
  margin-right: 5%;
  letter-spacing: 0.7px;
}

.ticketInputGroup {
  flex-direction: row;
}

.featureSubmit {
  display: flex;
  align-items: center;
}

.featureSubmitGroup {
  justify-content: space-between;
  margin-top: 5%;
}

.featureContainer {
  width: 80%;
  margin: 28px 0;
}

.featureHeader {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: $green300;
}

.featureLabel {
  color: $white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.featureSwitch {
  height: 28px;
  width: 48px;
}

.featureLabelPlacementStart {
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 0;
  width: 100%;
}

.featureBarStyle {
  border: 1px solid $lightWhite;
  border-radius: 30px;
  background-color: $transparent;
  opacity: 1;
  height: 20px;
  margin-top: -10px; /* half of height to vertically center */
  width: 36px;
}

.featureThumbStyle {
  color: $lightWhite;
  margin-top: -18px;
  margin-left: -12px;
}

.featureThumbSwitchedStyle {
  color: $white;
  margin-right: -6px;
}

.featureIsChecked {
  + .featureBarStyle {
    border: 1px solid $white;
    box-shadow: 0 0 0.5px 0.5px $darkWhite;
    background-color: $lightWhite;
    opacity: 1;
  }
}

.userListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addUserButton {
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  background-color: $darkBlueGrey;
  border: none;
  color: $white;
  width: 72px;
  padding: 0;
}

.addUserButtonLabel {
  margin: 0;
}

.addUserInnerButton {
  padding: 1px 1px 0;
  border-radius: 50%;
  border: 1px solid $white;
  margin-left: 5px;
}

.userNameInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
}

.lastNameInput {
  margin-right: 3%;
}

.userError {
  margin-top: -5%;
  color: $red600;
}

.userCheckbox {
  width: auto;
  margin-left: 8%;
}

.editSubdomainButton {
  padding: 0;
  border: none;
  margin: 0;
  background-color: $darkBlueGrey;
  outline: none;
  fill: $grey250;
  cursor: pointer;
}

.subdomainEditing {
  background-color: $grey250;
  color: $grey600;
  border-radius: 3px;
  transition: background-color 0.2s;
  width: 60%;
  min-width: 250px;
}

.subdomainSubmitGroup {
  margin-top: 2%;
}

.userCreditForm {
  display: flex;
  flex-direction: column;
}

.userCreditsContainer {
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
}

.userCreditInputLabel {
  font-size: 12px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-right: 15px;
  width: 200px;
}

.userCreditInputContainer {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.pdfRegenerationForm {
  display: flex;
  flex-direction: column;
}

.pdfRegenerationContainer {
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
}

.pdfRegenerationInputLabel {
  font-size: 12px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-right: 15px;
  width: 200px;
}

.pdfRegenerationInputContainer {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
