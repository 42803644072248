@import 'styles/shared/colors.css';
@import 'internal-tools/styles/colors.css';

.submitButton {
  border-radius: 2px;
  width: 36%;
  background-color: $white;
  padding: 8px 0;
  height: 36px;
  line-height: 24px;
}

.submitButtonDisabled {
  /* background-color/color styles are overridden by Material-UI in v3.8 */
  background-color: $blueGrey700 !important;
  color: $black !important;
}

.submitButtonLabel {
  font-size: 12px;
  padding-left: 4%;
  padding-right: 4%;
}

.subdomainSubmit {
  composes: submitButton;
  width: 45%;
  max-width: 125px;
}

.userSubmit {
  composes: submitButton;

  /* color styles are overridden by Material-UI in v3.8 */
  background-color: $blueGrey600;
  color: $white !important;
}

.userSubmitDisabled {
  composes: userSubmit;

  /* background-color styles are overridden by Material-UI in v3.8 */
  background-color: $grey400 !important;
}

.progress {
  vertical-align: text-bottom;
  color: $white;
}

.userProgress {
  composes: progress;
  composes: userSubmit;
}

.outlinedInput {
  border: 1px solid $grey300;
}

.menuPaper {
  width: 256px;
}

.menuItem {
  font-size: 15px;
  line-height: 32px;
  min-height: 32px;
  padding-top: 0;
  padding-bottom: 0;
}

.menuItemSelected {
  color: $grey850;
  font-weight: 700;
}

.formControlRoot {
  margin-top: 15px;
  width: 258px;
  margin-bottom: 15px;
}

.selectRoot {
  font-size: 15px;
}
