@import "styles/shared/colors.css";

.modalBackground {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
}

.modalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.modalLabel {
  color: $grey600;
  font-size: 13.5px;
  margin-bottom: 8px;
}

.modalInput {
  margin-bottom: 16px;
  font-size: 13.5px;
  padding: 9px;
  border-radius: 3px;
  border: 1px solid $grey250;
  width: 95%;
  outline: none;
}

.modalTicketInput {
  width: 45%;
  margin-bottom: 0;
}

.group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.submit {
  border: none;
  padding: 10px 20px;
  color: $white;
  width: auto;
  margin-bottom: 0;
  background-color: $lightBlue600;
}

.submit:disabled {
  background-color: $lightBlue100;
}

.modalContainer {
  margin-right: 7%;
  margin-top: -10%;
  display: flex;
  justify-content: center;
  width: calc(45vw - 20%);
  padding: 3%;
  z-index: 3;
  background-color: $white;
  min-height: 350px;
}
